import { Outlet, Navigate } from 'react-router-dom';
import { Suspense, lazy } from 'react';
import ErrorBoundary from '@/components/ErrorBoundary';
import PageLoading from '@/components/PageLoading';
import Layout from './components/Layout';

const Home = lazy(() => import('@/pages/Home'));
const Demo = lazy(() => import('@/pages/Demo'));

export const router = [
  {
    path: '/',
    errorElement: <ErrorBoundary />,
    element: (
        <Suspense fallback={<PageLoading />}>
          <Layout>
            <Outlet />
          </Layout>
        </Suspense>
    ),
    children: [
      {
        path: '',
        element: <Home />,
      },
      // { path: 'home', element: <Home /> },
    ],
  },
  {
    path: '/demo',
    element: <Demo />
  },
  {
    path: '*',
    element: <div>404</div>,
  },
];

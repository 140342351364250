import { Radio } from 'antd';
import { useTranslation } from 'react-i18next';


import style from './style.less';
import { useGlobalStore } from '@/store';

const options = [{
  label: '中文',
  value: 'zh-CN'
}, {
  label: 'English',
  value: 'en'
}]

const Language = () => {
  const { i18n } = useTranslation();

  const { language, setLanguage } = useGlobalStore();

  const changeLanguage = (value: string) => {
    setLanguage(value);

    i18n.changeLanguage(value)
  }

  return (
    <Radio.Group
      size="small"
      className={style.radios}
      onChange={({ target: { value }}) => {
        changeLanguage(value);
      }}
      options={options}
      value={language}
      optionType="button"
      buttonStyle="solid"
    />
  )
}

export default Language;

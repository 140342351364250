import { create } from 'zustand';
import { persist, devtools, createJSONStorage } from 'zustand/middleware';
import { getDefaultLanguage } from '@/utils';

interface UserState {
  language: string;
  setLanguage: (value: string) => void;
}

export const globalStore = create<UserState>()(
  devtools(
    persist(
      set => ({
        language: getDefaultLanguage(),
        setLanguage: (value) => {
          set({
            language: value
          })
        }
      }),
      {
        name: 'global-storage',
        storage: createJSONStorage(() => localStorage),
      },
    ),
  ),
);

export const useGlobalStore = globalStore;


import styles from './style.less';

const Footer = () => (
    <div className={styles.footer}>
      <div className={styles.copyright}>
        © 2024 Oceandata. ALL Rights Reserved
      </div>
    </div>
  )

export default Footer;

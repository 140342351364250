import React, { FC } from "react";
import classNames from "classnames";

import styles from './style.less';


interface WrapProps extends React.HTMLAttributes<HTMLDivElement> {
  children: React.ReactNode;
  className?: string;
}

const Wrap: FC<WrapProps> = (props) => {
  const { children, className = '', ...rest } = props;

  return (
    <div className={classNames(className, styles['main-width'])} {...rest}>
      {children}
    </div>
  )
}
export default Wrap;

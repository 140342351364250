
import { createRoot } from 'react-dom/client';
import { ConfigProvider } from 'antd';
import { RouterProvider, createBrowserRouter } from 'react-router-dom';
import { primary } from '@/constant/color';

import { router } from './routes';
import PageLoading from './components/PageLoading';
import "./i18n";

import './app.less';


const dom = document.getElementById('root');

const root = createRoot(dom as Element);

root.render(
  <ConfigProvider theme={{
    token: {
      colorPrimary: primary,
      itemSelectedBg: primary,
    },
  }}>
    <RouterProvider
      router={createBrowserRouter(router, {
        basename: '/',
      })}
      future={{ v7_startTransition: true }}
      fallbackElement={<PageLoading />}
    />
  </ConfigProvider>
);

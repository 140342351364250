import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from 'i18next-browser-languagedetector';
import zhcn from "./locales/zh_CN.json";
import en from "./locales/en.json";
import { globalStore } from "@/store";

const currentLanguage = globalStore.getState().language;

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: {
        translation: en
      },
      'zh-CN': {
        translation: zhcn
      }
    },
    lng: currentLanguage,
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export const waitTime = (time = 100) =>
  new Promise(resolve => {
    setTimeout(() => {
      resolve(true);
    }, time);
  });


 export const getDefaultLanguage = (): string => {
  const browserEnvLang =  navigator.language;

  const lang = browserEnvLang === 'zh-CN' ? browserEnvLang : 'en';

  return lang;
}


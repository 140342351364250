import { Anchor } from 'antd';
import { useTranslation } from 'react-i18next';


import Wrap from '@/components/Wrap';
import Logo from '@/components/Logo';
import Language from '@/components/Layout/Language';

import styles from './style.less';

const Nav = () => {
  const { t } = useTranslation();

  const list = [
    {
      key: 'banner',
      href: '#banner',
      title: t('nav.home'),
    },
    {
      key: 'actions',
      href: '#actions',
      title: t('nav.tech_actions'),
    },
    {
      key: 'adv',
      href: '#adv',
      title: t('nav.tech_adv'),
    },
    {
      key: 'contract',
      href: '#contract',
      title: t('nav.contract'),
    },
  ]

  return (
    <header className={styles['nav-container']}>
      <Wrap>
        <div className={styles['nav-header']}>
          <Logo />
          <div className={styles['nav-anchor']}>
            <Anchor
              bounds={10}
              affix={false}
              direction="horizontal"
              offsetTop={78}
              items={list}
            />
          </div>

          <div className={styles['nav-actions']}>
            <Language />
            {/* <Button ghost type="primary" size="small" onClick={changeLanguage}>{language === zh ? 'English' : '中文'}</Button> */}
          </div>
        </div>
      </Wrap>
    </header>
  );
}
export default Nav;
